import * as React from 'react'

// Types
import { IconSize } from '@/lib/enums'

function Menu({
  color = 'currentColor',
  size = IconSize.Large,
  className
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5H18M22 12H2M14 19H2"
        stroke={color}
        strokeWidth={2}
      />
    </svg>
  )
}

export default React.memo(Menu)
